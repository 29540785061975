<!-- @Author: Yu_Bo -->
<template>
    <div class='sound_preview zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="690px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="audio-container">
                <div class="creation-cover-img">
                    <img v-if="info.avatar" :src="info.avatar" alt="">
                </div>
                <div class="audio">
                    <audio-player class="audio-box" :fileurl="info.production_file" controls=""></audio-player>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="btnBgColor_blue_empty_no_grey" size="small" v-if="is_downloadbtn">下载音频</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" v-if="is_copybtn">复制音频链接</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import audioPlayer from '@/components/audioPlayer/index.vue'

export default {
    components: { audioPlayer },
    props: {
        title: {
            type: String,
            default: '预览'
        },
        is_downloadbtn: {
            type: Boolean,
            default: true
        },
        is_copybtn: {
            type: Boolean,
            default: true
        },
        info: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            dialogVisible: false,
            playShow: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn() {
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        play_btn() {

        },
        stop_btn() {

        }
    },
}
</script>

<style lang='scss' scoped>
.sound_preview {
    .video_main {
        width: 100%;

        .main_con {
            position: relative;
            width: 486px;
            height: 274px;
            margin: auto;
            background: #000;

            audio {
                display: block;
                width: 100%;
                height: 100%;
            }

            .play_box {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                padding: 20px 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    cursor: pointer;
                    display: block;
                    width: 20px;
                    height: 20px;
                }

                .pay_time {
                    width: 360px;
                    height: 5px;
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 3px;

                    span {
                        display: block;
                        width: 121px;
                        height: 5px;
                        background: #FFFFFF;
                        border-radius: 3px;
                    }
                }

                .time {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }

    .audio-container {
        margin-left: calc(50% - 486px / 2);
        width: 486px;
        height: 274px;
        overflow: hidden;
        position: relative;
        .audio {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 486px;
        }

        .creation-cover-img {
            width: 486px;
            height: 274px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}</style>
