<template>
    <div class="search-c">
        <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
            <el-form-item label="" props="name">
                <el-input size="small" v-model="params.name" prefix-icon="el-icon-search" :placeholder="placeholderTxt"
                    @change="search" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="" props="name_mobile" v-if="is_name_mobile">
                <el-input size="small" v-model="params.name_mobile" prefix-icon="el-icon-search" placeholder="请输入客户姓名/手机"
                    @change="search" :clearable="true"></el-input>
            </el-form-item>

            <el-form-item label="" props="identity" v-if="is_identity">
                <el-select v-model="params.identity" placeholder="选择用户身份类型" @change="search" :clearable="true">
                    <el-option v-for="item in user_identity_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="order_source" v-if="is_order_source">
                <el-select v-model="params.order_source" placeholder="选择作品来源" @change="search" :clearable="true">
                    <el-option v-for="item in order_source_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间" props="create_time" style="margin-right: 100px;" v-if="is_create_time">
                <div class="time_date">
                    <el-date-picker style="width:100%" size='small' v-model="params.create_time" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        @change="search" :clearable="true">
                    </el-date-picker>
                </div>
            </el-form-item>
        </el-form>
        <el-button v-if="is_export" size="small" class="leading-out" icon="el-icon-upload2" @click="exportBtn">导出</el-button>
    </div>
</template>
<script>
export default {
    props: {
        is_export: {
            type: Boolean,
            default: false
        },
      
        is_name: {
            type: String,
            default: ''
        },
        is_name_mobile: {
            type: Boolean,
            default: false
        },
        is_identity: {
            type: Boolean,
            default: false
        },
        is_order_source: {
            type: Boolean,
            default: false
        },
        is_create_time: {
            type: Boolean,
            default: false
        },
        user_identity_list: {
            type: Array,
            default: () => []
        },
        order_source_list: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            params: {}
        }
    },
    created() {
    },
    watch: {

    },
    computed: {
        placeholderTxt() {
            let txt = ''
            if (this.is_name == 'video') {
                txt = '请输入视频作品名称'
            }
            if (this.is_name == 'audio') {
                txt = '请输入音频作品名称'
            }
            if (this.is_name == 'draw') {
                txt = '请输入绘画作品名称'
            }
            if (this.is_name == 'comic') {
                txt = '请输入漫画作品名称'
            }
            return txt
        }
    },
    methods: {
        search() {
            this.$emit('search', this.params)
        },
        resetFrom() {
            for (let prop in this.params) {    // 注意区分 for...of 的区别,for...of是遍历对象中的值
                delete this.params[prop];// 这里注意：如果对象属性是变量，不可用"."的格式处理访问
            }
        },
        // 导出
        exportBtn(){
          this.$emit('export', this.params)
        },
    }
}
</script>
<style lang="scss" scoped>
.search-c {
    display: flex;
    position: relative;

    .input-box {
        width: 160px;
    }

    .leading-out {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.flex_box {
    display: flex;
    align-items: center;
}

.time_type {
    width: 148px;
    height: 32px;

    ::v-deep .el-input__inner {
        background: #F4F4F4;
        border-radius: 4px 0 0 4px;
        border: none;
        width: 148px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

    }
}

.time_date {
    width: 312px;
    height: 32px;

    .el-input__inner {
        border-radius: 0 4px 4px 0;
        // border: 1px solid #dbdbdb;

    }

    .el-date-editor {
        width: 100%;
    }
}
</style>
